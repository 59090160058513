.contactFormImg img {
  width: 100%;
  height: 100%;
}

.rightPartContact {
  font-style: normal;
  margin: 0 1em;
}
.contactForm {
  width: 90%;
  margin: 0 auto;
}
.contactForm input,
.contactForm textarea,
.contactForm select {
  border: none;
  background-color: transparent;
  border-bottom: 2px solid #d5d5d5;
  margin-top: 50px;
  outline: none;
  padding: 12px 0;
  color: #757575;
}
.contactForm textarea {
  height: 140px;
}
.contactLandingPgae {
  padding-bottom: 90px;
}
.content-wrapper.contactContentWrapper {
  background: #12141D;
}
.contactLandingPgae .homeContentInner * {
  color: #fff;
}
.contactLandingPgae .homeContentInner h5 {
  font-size: 20px;
}
.contactLandingPgae .homeContentInner p {
  font-size: 16px;
  margin-bottom: 0;
}
.contactLandingPgae .homeContentInner a {
  color: #fff !important;
}
.contactLandingPgae .contactInformation {
  padding: 25px 0;
  border-bottom: 1px solid #fff;
}

.contactFormBlock {
  position: relative;
  overflow: hidden;
}
.contactFormBox {
  border-radius: 20px;
  margin-right: 70px;
  margin-bottom: -63px;
}

.contactaddres a {
  max-width: 450px;
  width: 100%;
  display: inline-block;
}
.contactFormBox iframe {
  height: 660px !important;
}
.contactFormBlock h1 {
  display: none;
  color: #fff;
  text-align: center;
}

@media only screen and (max-width: 1400px) {
  .contactFormBox {
    margin-right: 20px;
  }
  .contactFormBox iframe {
    height: 720px !important;
  }
}

@media screen and (max-width: 1024px) {
  .contactContentWrapper .serviceLandingPage .row {
    flex-direction: column-reverse;
  }
  .contactLandingPgae .homeContentInner h1 {
    display: none;
  }
  .contactFormBlock h1 {
    display: block;
    margin-bottom: 25px;
  }
  .contactFormBox {
    margin-right: 0;
    margin-top: 30px;
  }
  .rightPartContact {
    margin: 0 !important;
  }
  .contactForm {
    width: 100%;
  }
  .halfContact {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .contactForm input,
  .contactForm textarea,
  .contactForm select {
    margin-top: 25px !important;
  }
}
@media screen and (max-width: 640px) {
  .halfContact .halfWidth {
    width: 100%;
  }
}
