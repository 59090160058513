.NavbarContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10000;
  transition: 0.4s ease all;
  padding: 0 20px;
  background-color: #fff;
}
.NavbarContainer.nav-bg {
  top: 0;
  background-color: #fff;
}
nav.NavbarContainer.nav-bg .wrapper {
  box-shadow: none;
}
.NavbarItems {
  padding: 5px 0;
  display: flex;
  align-items: center;
  font-size: 1rem;
  z-index: 10000;
}

.nav-bg {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06);
}
.navbar-logo {
  justify-self: start;
  align-self: center;
  cursor: pointer;
}
.navbar-logo img {
  display: block !important;
  object-fit: contain;
  width: 130px;
}
.right-header {
  flex: 3;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nav-menu {
  display: flex;
  margin: 1em auto;
  text-align: center;
  list-style: none;
}
.nav-links {
  position: relative;
  align-self: center;
  color: #111111;
  text-decoration: none;
  font-weight: 500;
  padding: 0.5rem 0;
  transition: all 0.5s ease-in-out;
  font-size: 16px;
  text-transform: capitalize;
}
.nav-menu li {
  position: relative;
  padding: 0 1rem;
}
.nav-menu li > ul {
  padding: 0px 0;
  background-color: #fff !important;
  border-radius: 16px;
  -webkit-box-shadow: 0px 1px 10px -2px rgb(144 19 254 / 20%);
  -moz-box-shadow: 0px 1px 10px -2px rgba(144, 19, 254, 0.2);
  box-shadow: 0px 1px 10px -2px rgb(144 19 254 / 20%);
  position: absolute;
  left: 0;
  top: 100%;
  width: 245px;
  margin-top: 10px;
  padding: 10px 0;
  opacity: 0;
  visibility: hidden;
  z-index: 9999;
  transition: all ease 0.4s;
  text-align: left;
}
.nav-menu li.submenu > a {
  padding-right: 25px;
}
.nav-menu li.submenu > a:before {
  position: absolute;
  content: '';
  top: 10px;
  right: 0;
  width: 15px;
  height: 15px;
  background-image: url('../../assets/btn_arrow_black.svg');
  background-repeat: no-repeat;
  background-size: contain;
  transform: rotate(90deg);
}
.nav-menu li.submenu:hover > ul {
  opacity: 1;
  visibility: visible;
  transition: all ease 0.3s;
  list-style: none;
}
.nav-menu li > ul li {
  padding-top: 5px;
  padding-bottom: 5px;
  list-style: none;
  line-height: 1.3;
}
.nav-menu li > ul li a {
  font-weight: 500;
  text-transform: capitalize;
}
.nav-menu li > ul li a:hover {
  color: #e074ea !important;
}
.nav-menu.active .nav-links:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 0;
  height: 2.5px;
  background: linear-gradient(120deg, #fcc1ff 20.69%, #b1fcfa 79.69%);
  transition: all 0.5s ease-in-out;
}
/* li a.active {
  background: linear-gradient(120deg, #FCC1FF 20.69%, #B1FCFA 79.69%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
} */
.nav-menu.active .nav-links:hover:before,
.nav-menu.active .nav-links.active:before {
  width: 100%;
}
.nav-links:hover {
  color: #000;
}
/* .nav-links:hover {
  background: linear-gradient(120deg, #5f45ff 20.69%, #0fe3ff 79.69%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
} */
.mobile-icon {
  color: #fff;
  font-size: 30px;
}

.nav-links-mobile {
  display: none;
}

.menu-icons {
  display: none;
}
.web {
  display: block;
}

.mobile {
  display: none;
}
.moveDeg{
transform: rotate(180deg);
transition: all 0.5s ease;
transform-origin: center center;
}
.reveDeg{
  transform: rotate(0deg);
  transition: all 0.5s ease;
  transform-origin: center center;

}

@media screen and (max-width: 1024px) {
  .right-header .header-btn {
    display: none;
  }
  .NavbarContainer {
    /* position: relative; */
    /* box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
      rgba(255, 255, 255, 0.8) -6px -2px 16px 0px; */
  }
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 90px;
    left: -110%;
    opacity: 1;
    transition: all 0.4s ease;
    padding: 0px;
    margin: -1px;
  }
  .nav-menu.active {
    background: #fff;
    color: white;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    position: fixed;
    height: 100%;
    top: 0;
    padding-top: 150px;
    z-index: -1;
    overflow: auto;
  }

  .menu-menu:nth-child(6) {
    color: black !important;
  }
  .nav-menu :nth-child(6) a {
    color: black !important;
  }

  .nav-links {
    border-radius: 10px;
    font-size: 17px;
    margin: 5px;
    display: flex;
    padding: 1rem;
  }
  .nav-links a.active {
    color: rgb(214, 214, 214) !important;
    font-weight: bold;
  }

  /* .navbar-logo {
    position: absolute;
    top: -0.6em;
    left: 0;
    transform: translate(25%, 50%);
  } */
  .right-header {
    justify-content: flex-start;
    flex-direction: row-reverse;
    align-items: center;
    grid-gap: 20px;
  }
  .navbar-logo img {
    display: block !important;
    object-fit: contain;
    width: 10rem;
  }
  .menu-icons {
    display: block;
    cursor: pointer;
    /* position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    transform: translate(-100%, 38%);
    font-size: 1.8rem; */
  }
  .mobile-icon {
    color: #000;
    font-size: 30px;
  }

  .nav-links-mobile {
    display: block;
    margin: 2rem auto;
    width: 200px;
  }
  .nav-links-mobile :hover {
    background: #fff;
    color: #f3f3f3;
    transition: 250ms;
  }
  .web {
    display: none;
  }
  .mobile {
    display: block;
  }
  .mobileBack {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  }
  .mobileBack .nav-links {
    padding-left: 27px;
  }
}

@media screen and (max-width: 1150px) {
}
@media screen and (max-width: 1250px) {
  .NavbarItems {
    /* width: 98%; */
  }
}
/*  */

@media screen and (max-width: 767px) {
  .nav-menu.active .nav-links:before {
    display: none;
  }
  .nav-links.active {
    color: #e074ea !important;
  }
}
