.wrapper {
  max-width: 1380px;
  margin: 0px auto;
  width: 90%;
  box-sizing: border-box;
  padding: 0 20px;
}
.header-wrapper {
  /* background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06);
  border-radius: 3em; */
  padding: 0.7em 2em;
  max-width: 1600px;
}
.content-wrapper {
  background: linear-gradient(
    62deg,
    rgba(249, 249, 249, 0.54) 0%,
    rgba(244, 225, 245, 0.54) 100%
  );
  width: 100%;
  padding-top: 8em;
  width: 100%;
}
.content-wrapper-service-det {
  background: linear-gradient( 245deg, #f7eff7 0, #f8f6f8 100% ) !important;
  width: 100%;
  padding-top: 8em;
  overflow: hidden;
}
.content-wrapper-serviceIndex {
  background: linear-gradient(
    145deg,
    rgb(240 254 254 / 100%) 0%,
    rgb(2247 248 255 / 100%) 51%,
    rgb(254 243 255 / 100%) 100%
  );
  width: 100%;
}
.content-wrapper-service-det h1 {
  font-size: 85px;
  line-height: 1;
}
.content-wrapper-serviceIndex #wordpress-development .serviceImageBox {
  top: unset;
  transform: unset;
  bottom: 0;
  width: 26%;
}
.content-wrapper-serviceIndex #web-hosting .serviceImageBox {
  top: unset;
  transform: unset;
  bottom: 0;
}
.content-wihtout-gradientService #email-newsletter-design .serviceImageBox {
  top: unset;
  transform: unset;
  bottom: 0;
  width: 25%;
}
.singleService h2 {
  font-size: 40px;
}
.footerPage {
  position: relative;
  background: #000;
  width: 100%;
  padding: 3em 0 1em;
  margin: 0;
}
.serviceBtnHomePage a {
  transition: 0.4s ease all;
}
.serviceBtnHomePage a:hover {
  color: #e074ea !important;
}
.serviceBtnHomePage a span {
  padding-left: 15px;
}
.content-wrapper-service {
  background: linear-gradient(
    145deg,
    rgb(254 243 255) 0%,
    rgb(247 248 255) 48%,
    rgb(240 254 254) 100%
  );
  margin-top: 20em;
  width: 100%;
  padding-bottom: 6em;
}

section {
  margin-top: 4em;
}
.modal-content {
  overflow: hidden;
  border-radius: 30px;
}
.modal-content .modal-header {
  padding: 0;
  border: none;
}
.modal-content .modal-header img {
  width: 100%;
}
.modal-content .modal-header .btn-close {
  position: absolute;
  top: 20px;
  right: 30px;
  font-size: 15px;
  outline: none;
  box-shadow: none;
}
.modal-content .modal-footer {
  display: none;
}
.divider {
  margin-top: 4em;
  background-color: #90909057;
  width: auto;
  height: 3px;
  padding: 0px;
}
.modal-content:after {
  position: absolute;
  content: '';
  right: -220px;
  bottom: -220px;
  width: 320px;
  height: 320px;
  background-image: url(../assets/audit-website-circle.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.modal-content .modal-body {
  padding: 40px 50px;
}
.modal-backdrop,
.modal {
  z-index: 99999 !important;
}
.testimonialHomePage .testnomialsBox {
  max-width: 900px;
  margin: auto;
}
.testimonialHomePage .testnomialCard {
  min-height: 365px;
  box-shadow: 0 0 14px 0 rgb(228 215 254 / 65%);
}
.testimonialHomePage .testnomialCard:before {
  position: absolute;
  content: '';
  top: -100px;
  left: -100px;
  width: 166px;
  height: 166px;
  background-image: url('../assets/home/happy-clients.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  animation: animName 7s linear infinite;
}
@keyframes animName {
 0%{
    transform: rotate(0deg);
   }
100%{
    transform: rotate(360deg);
   }
}
.testimonialHomePage .testnomialCard .testnomialCard-content1 {
  padding: 20px;
  padding-top: 40px;;
  padding-bottom: 60px;
}
.testimonialHomePage .testnomialCard .testnomialCard-content1:before {
  position: absolute;
  content: '';
  top: 50px;
  left: 40px;
  width: 38px;
  height: 30px;
  background-image: url('../assets/home/quote.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.testimonialHomePage .testnomials-heading {
  padding-bottom: 40px;
}
.testimonialHomePage .testnomialCard .testnomialCard-content1 p {
  color: #000;
  line-height: 1.6;
}
.testimonialHomePage .testnomialCard h6 {
  font-size: 18px;
  text-align: right;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 5px;
}
.testimonialHomePage .testnomialCard .testimomial-by {
  position: absolute;
  bottom: 40px;
  right: 50px;
}
.testimonialHomePage .testnomialCard .testimomial-by h6:last-child {
  color: rgb(0 0 0 / 40%);
  margin-bottom: 0;
}
.homeWeb .homeContent:before {
  top: 28.5%;
}
.testimonialHomePage .testnomialCard .rounded-circle {
  transition: 0.4s ease all;
}
.testimonialHomePage .testnomialCard .rounded-circle:hover {
  background-color: #e074ea !important;
}


/* second title */
h1 {
  font-size: 4em;
  font-weight: 800;
}

/* second title */
h2 {
  font-size: 3em;
  font-weight: 800;
}

/* subtitle */

h3 {
  font-size: 2em;
  font-weight: 700;
}

/* card code*/

h4 {
  font-size: 1.7em;
  font-weight: 700;
}

/* points something */
h5 {
  font-size: 1.4em;
  font-weight: 700;
}

/* tackle  */
h6 {
  margin-top: 1em;
  font-size: 1em;
}
p {
  font-size: 19px;
}

a {
  text-decoration: none;
}
a:hover {
  color: #5f45ff;
}

@media screen and (max-width: 780px) {
  h2 {
    font-size: 2.3em;
  }
  h3 {
    font-size: 1.7em;
  }
}

#root {
  /* background-color: #f9f9f9; */
}

.aboutTopBlock .homeContent:before,
.serviceLandingPage .homeContent:before {
  top: 44%;
  right: 160px;
  height: 290px;
}
.aboutTopBlock .homeImageBox {
  width: 120%;
  margin-left: -10%;
  margin-bottom: -50px;
  padding-top: 50px;
}
.serviceLandingPage .homeImageBox {
  position: absolute;
  width: 50%;
  max-width: 900px;
}
.serviceLandingPage {
  position: relative;
  min-height: 330px;
}
.serviceLandingPage .homeImageBox img {
  width: 100%;
}
.ProjectRatingPageHome .funFact_review_items {
  position: relative;
  padding-left: 57px;
}
.ProjectRatingPageHome .funFact_review_items .review_icon {
  display: flex;
  align-items: center;
  position: absolute;
  width: 50px;
  height: 55px;
  left: -15px;
  top: 14px;
}
.ProjectRatingPageHome .funFact_review_items .review_icon img {
  width: 100%;
}
.ProjectRatingPageHome .funFact_review_items:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 70px;
  height: 70px;
  background: linear-gradient(
    135deg,
    rgb(250 205 254 / 80%) 0%,
    rgb(217 220 253 / 80%) 51%,
    rgb(181 249 250 / 80%) 100%
  );
  border-radius: 100%;
  opacity: 0.3;
}
.ProjectRatingPageHome .singleRatingHome {
  width: 20%;
  flex: none;
}
.CultureRowBlock {
  justify-content: space-between;
  grid-gap: 20px;
  padding: 0 20px;
  max-width: 100%;
  margin: auto;
}
.CultureRowBlock .col {
  width: calc(20% - 20px);
  padding: 0;
}
.CultureRowBlock .OurWorkCulture img {
  width: 100%;
}
.aboutWorkCulture {
  padding-bottom: 100px;
}

.singleService {
  position: relative;
  padding: 140px 0;
}
.singleService .serviceList {
  margin: 0;
}
.singleService .serviceList h2 {
  font-size: 40px;
}
.singleService .serviceList .wrapper {
  max-width: 1180px;
}
.singleService .serviceDetail p,
.singleService .serviceDetail a {
  display: inline;
  margin-bottom: 0;
  line-height: 1.6;
}
.singleService .serviceDetail a {
  margin-left: 5px;
  font-style: italic;
  border-bottom: 1px solid #d66ad6;
}
.content-wihtout-gradientService .serviceImageBox {
  position: absolute;
  top: 50%;
  left: -10%;
  transform: translateY(-50%);
  width: 38%;
  z-index: 1;
}
.content-wihtout-gradientService .serviceImageBox img {
  width: 100%;
}
.content-wrapper-serviceIndex .serviceImageBox {
  position: absolute;
  top: 50%;
  right: -10%;
  transform: translateY(-50%);
  width: 38%;
  z-index: 1;
}
.content-wrapper-serviceIndex .serviceImageBox img {
  width: 100%;
}
.contactLandingPage {
  background-image: url("../assets/contact/contact-page-bg.png");
  background-repeat: no-repeat;
  background-position: center right;
  background-size: cover;
  position: relative;
  z-index: 1;
}
#learndash-development .serviceImageBox,
#web-maintenance .serviceImageBox {
  width: 50%;
  right: -15%;
}
#web-development .serviceImageBox,
#seo .serviceImageBox {
  width: 50%;
  left: -15%;
}

.contactLandingPage .homeContent .homeContentInner {
  max-width: 550px;
  width: 100%;
}
.contactLandingPage .serviceLandingPage .homeImageBox {
  max-width: 460px;
  top: -40px;
}
.serviceDetPageExp {
  position: relative;
  padding-top: 150px;
  padding-bottom: 150px;
}
.serviceDetPageExp .serviceExpImg {
  position: absolute;
  top: 70px;
  right: -100px;
  width: 50%;
  max-width: 820px;
}
.serviceDetPageExp .serviceExpImg img {
  max-width: 100%;
}
.serviceDetPageExp .serviceExpContent p {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.6;
}
.serviceExpContent {
  position: relative;
}
.serviceExpContent:before {
  position: absolute;
  content: '';
  left: -50px;
  top: -70px;
  width: 150px;
  height: 150px;
  background: linear-gradient( 145deg, rgb(254 243 255) 0%, rgb(247 248 255) 48%, rgb(240 254 254) 100% );
  border-radius: 100%;
  z-index: -1;
}
.serviceExpContent:after {
  position: absolute;
  content: '';
  right: -50px;
  bottom: -70px;
  width: 70px;
  height: 70px;
  background-image: url('../assets/service/service-detail-icon.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: -1;
}
.serviceBrand .serviceContain {
  position: relative;
  max-width: 900px;
  margin: auto;
  width: 100%;
  padding-bottom: 50px;
}
.serviceBrand .serviceContain p {
  margin-top: 12px;
}
.brandBox .brandItems p {
  color: #747373;
  font-size: 17px;
  font-weight: 800;
  opacity: 0.32;
  text-transform: uppercase;
  display: flex;
  flex-wrap: nowrap;
}
.serviceBrand .serviceContain::before {
  position: absolute;
  content: '';
  left: -100px;
  top: -20px;
  width: 40px;
  height: 40px;
  background-image: url('../assets/service/service-x-icon.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: -1;
}
.serviceBrand .serviceContain::after {
  position: absolute;
  content: '';
  right: -50px;
  bottom: 20px;
  width: 40px;
  height: 40px;
  background-image: url('../assets/service/service-circle-icon.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: -1;
}
.contactLandingPgae .homeImageBox {
  max-width: 440px;
}
.nav-menu > li > .nav-links:after {
  position: absolute;
  content: '';
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  opacity: 0;
  background: var(--primary);
  transition: 0.4s ease all;
}
.nav-menu > li > .nav-links.active:after,
.nav-menu > li > .nav-links:hover:after {
  opacity: 1;
}
.thank-you-block {
  padding-top: 40px;
  padding-bottom: 80px;
  text-align: center;
}
.thank-you-wrapper {
  background: #12141D;
  height: 100vh;
  display: flex;
  align-items: center;
}
.thank-you-wrapper h1,
.thank-you-wrapper p {
  color: #fff;
}
.thank-you-wrapper h1 {
  margin-bottom: 20px;
}
.thank-you-wrapper p {
  margin-bottom: 30px;
}
.thank-you-wrapper .thank-you-block img {
  width: 120px;
}



@media only screen and (min-width: 2000px) {
  .serviceDetPageExp .serviceExpImg {
      position: absolute;
      right: 0;
    }
}

@media screen and (max-width: 1366px) {
  h1 {
    font-size: 3.8em;
  }
  .ProjectHomePage {
    padding-top: 80px;
  }
}
@media screen and (max-width: 1200px) {
  .content-wrapper-service-det h1 {
    font-size: 70px;
  }
  .ProjectRatingPageHome .singleRatingHome {
    width: 25%;
  }
  .aboutTopBlock .homeImageBox {
    width: 100%;
    margin: 0 !important;
  }
  .aboutTopBlock {
    padding-bottom: 50px;
  }
  .serviceDetPageExp {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
@media screen and (max-width: 1024px) {
  .wrapper {
    width: 100%;
  }
  p {
    font-size: 18px;
  }
  .testimonialHomePage .testnomialCard:before {
    top: -80px;
    left: -15px;
    width: 116px;
    height: 116px;
  }
  .testimonialHomePage .testnomialCard {
    min-height: 360px;
  }
  .serviceBrand .serviceContain h2 br {
    display: none;
  }
  #learndash-development .serviceImageBox, #web-maintenance .serviceImageBox {
      width: 100%;
      right: 0;
  }
  #web-development .serviceImageBox, #seo .serviceImageBox {
      width: 100%;
      left: 0;
  }
  .serviceDetailLanding .flex-row-reverse {
    padding-bottom: 0 !important;
  }
  .content-wrapper-service-det h1 {
    font-size: 60px;
  }
  .singleService {
    padding: 40px 0;
  }
  .content-wihtout-gradientService .serviceImageBox,
  .content-wrapper-serviceIndex .serviceImageBox {
    position: unset;
    top: unset;
    left: unset;
    transform: unset;
    width: 100%;
    padding-bottom: 25px;
    max-width: 550px;
  }
  .serviceLandingPage .homeImageBox {
    position: relative;
    width: 100%;
  }
  .contactLandingPgae .homeImageBox {
    right: 0;
    max-width: 370px;
    top: 0;
  }
  .ProjectRatingPageHome .singleRatingHome {
    width: 50%;
  }
  section {
    margin-top: 2em;
  }
  h1 {
    font-size: 3.4em;
  }
  .CultureRowBlock {
    margin-top: 0 !important;
  }
  .ProjectHomePage {
    padding-top: 0;
  }
  .serviceDetPageExp .serviceExpImg {
    position: relative;
    top: unset;
    right: unset;
    width: 100%;
    max-width: 100%;
    padding-bottom: 40px;
  }
  .serviceDetPageExp {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .serviceDetPageExp .serviceExpImg img {
    max-width: 600px;
  }
  .norWordBreak h3 {
    font-size: 40px;
  }
  .norWordBreak {
    min-width: 800px;
  }
  .content-wrapper-service {
    margin-top: 17em;
  }
  .serviceBrand .serviceContain {
    padding-bottom: 0;
  }
  .testimonialHomePage .testnomialCard .rounded-circle {
    width: 40px !important;
    height: 40px !important;
  }
  .testimonialHomePage .testnomialCard .left-circle {
    left: -0.9em !important;
  }
  .testimonialHomePage .testnomialCard .right-circle {
    right: -0.9em !important;
  }
}
@media screen and (max-width: 992px) {
  .serviceDetPageExp .row {
    flex-direction: column-reverse;
  }
  .norWordBreak {
    min-width: 500px;
  }
  .serviceBrand .serviceContain::before {
    left: 0;
    top: -40px;
  }
  .serviceBrand .serviceContain::after {
    right: 0px;
  }
}
@media screen and (max-width: 767px) {
  .thank-you-wrapper {
    height: 70vh;
  }
  .testimonialHomePage .testnomialCard .testnomialCard-content1 p {
    font-size: 16px;
  }
  .testimonialHomePage .testnomialCard .testimomial-by {
    bottom: 25px;
    right: 30px;
  }
  .testimonialHomePage .testnomialCard h6 {
    bottom: 30px;
  }
  .testimonialHomePage .testnomialCard {
    padding: 30px 10px !important;
  }
  .testimonialHomePage .testnomialCard .testnomialCard-content1:before {
    top: 40px;
    left: 10px;
  }
  .testimonialHomePage .testnomialCard:before {
    top: -50px;
    left: -15px;
    width: 86px;
    height: 86px;
  }
  .aboutWorkCulture {
    padding-top: 60px;
  }
  .serviceBrand .ServiceEndButton {
    margin-top: 25px !important;
  }
  .serviceDetailContent {
    margin-top: 0 !important;
  }
  .norWordBreak h3 {
    font-size: 32px;
  }
  .servicePageHome .serviceDetBussinessTitle {
    width: 100%;
    height: unset;
    background: unset !important;
    border-radius: unset;
  }
  .servicePageHome .serviceDetBussinessTitle .norWordBreak {
    left: 0;
    text-align: center;
    min-width: unset;
  }
  .servicePageHome .serviceContentDet {
    position: unset;
    top: unset;
    transform: unset;
    padding-top: 40px;
  }
  .detailPageServices .serviceHomeCardBox {
    padding-top: 0;
  }
  .serviceDetPageExp .serviceExpContent p {
    font-size: 16px;
  }
  .serviceDetImageBox {
    position: relative;
    left: unset;
    top: unset;
    width: 100% !important;
    padding-bottom: 30px;
    z-index: 1
  }
  #seo .serviceDetImageBox,
  #ui-ux-development .serviceDetImageBox {
    width: 100% !important;
  }
  #web-maintenance .serviceDetImageBox {
    top: unset;
    width: 100% !important;
  }
  .serviceDetImageBox img {
    max-height: 280px;
    object-fit: contain !important;
  }
  .content-wrapper-service-det h3 {
    font-size: 1.5em;
  }
  .content-wrapper-service-det h1 {
    font-size: 36px;
  }
  .content-wihtout-gradientService .serviceImageBox, .content-wrapper-serviceIndex .serviceImageBox {
    width: 100%;
  }
  .singleService {
    padding: 20px 0;
  }
  .singleService h2 {
    font-size: 32px;
  }
  .contactLandingPgae .homeImageBox {
    max-width: 70%;
  }
  .serviceLandingPage .homeImageBox {
    position: unset;
    width: 100%;
    margin: auto;
  }
  h1 {
    font-size: 2.2em;
  }
  .content-wrapper-service {
    margin-top: 15em;
    padding-bottom: 3em;
  }
  .header-wrapper {
    padding: 0.5em 1em;
  }
  .content-wrapper {
    padding-top: 6em;
  }
  .review_title h2 {
    font-size: 32px;
  }
  .review_comments p {
    font-size: 16px;
  }
  .ProjectHomePage {
    padding-bottom: 0;
    margin-top: -30px;
  }
  .CultureRowBlock {
    grid-gap: 0px;
  }
  .CultureRowBlock .col {
    width: calc(50% - 10px);
    padding: 0;
  }
  .CultureRowBlock .col:nth-child(odd) {
    margin-bottom: 2em !important;
  }
  .CultureRowBlock .col:nth-child(even) {
    margin-top: 2em !important;
  }
  .serviceDetPageExp {
    padding-top: 0;
  }
}
@media screen and (max-width: 640px) {
  .ProjectRatingPageHome .row {
    margin-top: 4em !important;
  }
  .ProjectRatingPageHome .funFact_review_items .review_icon {
    width: 40px;
    height: 45px;
    left: -5px;
  }
  .ProjectRatingPageHome .funFact_review_items:after {
    width: 60px;
    height: 60px;
  }
}
@media screen and (max-width: 480px) {
  .contactLandingPgae .homeImageBox {
    max-width: 90%;
  }
}
