:root {
  --primary: linear-gradient(135deg, #facdfe 0%,#d9dcfd 48%,#b5f9fa 100%);
  --hoverprimary: linear-gradient(135deg, #b5f9fa 0%,#d9dcfd 48%,#facdfe 100%);
  --btn-color: linear-gradient(135deg, #facdfe 0%,#d9dcfd 48%,#b5f9fa 100%);
}

.btn1,
.btn-sm {
  font-size: 1px;
  padding: 10px 20px;
  border-radius: 8px;
  outline: none;
  border: none;
  cursor: pointer;
  text-transform: capitalize;
}

.btn:hover {
  color: #000;
  transform: scale(105%);
  transition: 250ms;
}

.btn--primary {
  background: var(--primary);
  transition: 0.4s ease all;
}
.btn--primary:hover {
  background-color: #e074ea;
}

.btn--outline {
  background: transparent;
  color: #000;
  padding: 8px 20px;
  border-radius: 8px;
  border: 1px solid var(--btn-color);
  transition: all 0.3s ease-out;
}

.btn--medium {
  position: relative;
  padding: 15px 20px;
  font-size: 15px;
  color: #000;
  border-radius: 3em;
  text-transform: uppercase;
  font-weight: 700;
  min-width: 190px;
  text-align: center;
  display: inline-block;
}
.btn--medium:after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 3em;
  background-image: linear-gradient(135deg, #97f6f7 0%,#dfe1f9 48%,#eeb6f3 100%);
  transition: opacity 0.5s ease-out;
  z-index: 2;
  opacity: 0;
  box-shadow: 0 4px 14px 0 rgb(228 215 254 / 65%);
}
.btn--medium span {
  position: relative;
  z-index: 11;
}
.btn--medium:hover:after {
  opacity: 1;
}
.btn--large {
  padding: 15px 50px;
  font-size: 25px;
  font-weight: 510;
  border-radius: 3em;

  color: #000;
}

/* -----------Responsive code-------------- */
@media screen and (max-width: 1150px) {
  .btn-sm {
    font-size: 15px;
    display: inline-block;
  }
}

@media screen and (max-width: 767px) {
  .btn--medium {
    min-width: 160px;
    font-size: 14px;
  }
}
@media screen and (max-width: 1073px) {
  .header-btn-none {
    display: none;
  }
}
