.mainFooter_copyright {
  position: relative;
  padding-top: 40px;
  text-align: center;
}
.mainFooter_copyright p {
  font-size: 15px;
  color: #fff;
}
.footerExplore span {
  font-weight: 500;
  cursor: pointer;
}
.footerInputField {
  width: 60%;
}
.footerInputField input {
  width: 100%;
  border: none;
  outline: none;
  border-radius: 3em;
  text-indent: 10px;
}
.footerInputBox p {
  font-weight: 500;
  color: #fff;
}
.footerExplore p {
  position: relative;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
  color: #fff;
}
.footerExplore p:before {
  position: absolute;
  content: '';
  left: 0;
  top: 5px;
  width: 18px;
  height: 18px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.footerExplore p.email-footer:before {
  background-image: url('../../assets/email-icon.png');
}
.footerExplore p.location-footer:before {
  background-image: url('../../assets/location-icon.png');
}
.footercontact p {
  padding-left: 30px;
  color: #fff;
}
.footerExplore h5 {
  font-weight: 800;
  color: #fff;
}
.mainFooter_nav1 p {
  max-width: 360px;
  font-size: 15px;
  font-weight: 500;
  color: #fff !important;
}
.footerExplore p a {
  transition: 0.4s ease all;
  color: #fff !important;
}
.footerExplore p a:hover {
  color: #e074ea !important;
}
.footerlinks a {
  text-transform: capitalize;
  color: #fff;
}
.footerPage .social-icons {
  display: flex;
  align-items: center;
  grid-gap: 20px;
  list-style: none;
  padding: 0;
  margin: 0;
}
.footerPage .social-icons a {
  display: block;
}
.footerPage .social-icons a img {
  width: 25px;
  height: 25px;
  object-fit: contain;
}
.mainFooter_image .footer_logo {
  width: 90px;
  margin-bottom: 12px;
}
.mainFooter_copyright p strong {
  background: linear-gradient(135deg, #facdfe 0%, #d9dcfd 48%, #b5f9fa 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#custom-link-dissable{
  pointer-events: none;
}
@media screen and (max-width: 1024px) {
  .mainFooter_nav1 {
    padding-bottom: 30px;
  }
  .footerPage .row .footer-services .footerExplore {
    padding-bottom: 40px;
  }
}
@media screen and (max-width: 767px) {
  .footerPage .row .footer-services {
    padding-top: 30px;
  }
  .mainFooter_copyright p strong {
    display: block;
  }
  .footerPage .col {
    flex: unset;
  }
  .mainFooter_nav1 {
    padding-bottom: 15px;
  }
  .footercontact {
    padding-top: 30px;
  }
}
