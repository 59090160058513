/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400&display=swap"); */

/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;800&display=swap"); */

/* @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,600&display=swap"); */

/* @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,500;0,600;0,700;0,800;0,900;1,700;1,800;1&display=swap"); */

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');

:root {
  --tawk-header-background-color: rgb(25, 26, 30);
  --tawk-header-text-color: rgb(255 255 255);
}

body > * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Lato", sans-serif;
  overflow-x: hidden;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  border-radius: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: linear-gradient(120deg, #fcc1ff 20.69%, #b1fcfa 79.69%);
  -webkit-text-fill-color: transparent;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(120deg, #fcc1ff 20.69%, #b1fcfa 79.69%);
}

.loginName {
  text-align: center;
}
.bg_home {
  background: linear-gradient(160deg, #fcc1ff 20.69%, #b1fcfa 79.69%);
}
.swiper-pagination {
  display: none;
}
a {
  color: black !important;
}

.tawk-custom-color
  .tawk-custom-border-color
  .tawk-button
  .tawk-button-circle
  .tawk-button-large {
  background-color: rgb(59, 226, 65) !important;
}
.tawk-mobile {
  background-color: rgb(30, 23, 233) !important;
}
