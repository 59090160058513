.tab-list {
  width: 80%;
  margin: 0 auto;
  text-align: center;
}
.tabs {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 1.5rem;
}
.serviceDetImageBox {
  width: 40% !important;
}
.tabs li {
  height: 44px;
  flex: 1 1;
  cursor: pointer;
  padding: 10px 20px;
  font-weight: bold;
  outline: 1px solid #dadada;
  text-transform: uppercase;
  border-radius: 16px;
}
.tabs li.active {
  background-image: linear-gradient(50deg, rgba(177,252,250,0.996078431372549) 0%, rgba(252,193,255,0.9999999999999999) 100%);
  outline: none;
  height: 46px;
}
.tab-content {
  margin-top: 3em;
  border-radius: 0 5px 5px 5px;
}
.portfolioListingPage.homeContent:after {
  position: absolute;
  content: '';
  top: -30px;
  left: 60%;
  width: 50px;
  height: 50px;
  background-image: url(../../assets/portfolio/work-bg-icon.png);
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
}
.content-wrapper-service-det {
  overflow: hidden !important;
}
.portfolioListing {
  padding-top: 70px;
  padding-bottom: 50px;
}
.portfolioListing .ourRecentWorkHomeBox {
  padding-left: 35px;
  padding-right: 35px;
  border-radius: 18px;
  height: unset;
}
.portfolioListing .ourRecentWorkHomeBox .portfolio_logo {
  width: unset;
  max-width: 220px;
  margin-bottom: 22px;
}
.portfolioListing .ourRecentWorkHomeBox .portfolio-content h4 {
  font-size: 32px;
  font-weight: 400;
  margin-bottom: 10px;
}
.portfolioListing .ourRecentWorkHomeBox h6 {
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 50px;
}
.portfolioListing .ourRecentWorkHomeBox .portfolio-content {
  max-width: 560px;
  width: 100%;
  padding-bottom: 35px;
}
.portfolioListing .ourRecentWorkHomeBox .portfolio_desktop {
  position: relative;
  margin-bottom: -50px;
}
.portfolioListing .ourRecentWorkHomeBox .portfolio_desktop img {
  width: 100%;
  max-height: 400px;
  object-fit: contain;
}
.portfolioListing .ourRecentWorkHomeBox .recentWorkButton {
  position: unset;
}
.portfolioListing .ourRecentWorkHomeBox .portfolio-content * {
  color: #000;
}

@media screen and (max-width: 1400px) {
  .tab-list {
    width: 100%;
  }
}
@media screen and (max-width: 1200px) {
  .portfolioListing .ourRecentWorkHomeBox .portfolio_desktop img {
    width: 100%;
    margin-left: unset;
    max-width: 600px;
  }
  .portfolioListing .ourRecentWorkHomeBox > .row {
    flex-direction: column-reverse;
  }
  .portfolioListing .ourRecentWorkHomeBox h6 {
    margin-bottom: 30px;
  }
  .portfolioListing .ourRecentWorkHomeBox .portfolio_desktop {
    margin-bottom: unset;
    text-align: center;
    padding-bottom: 20px;
  }
  .portfolioListing .ourRecentWorkHomeBox .portfolio-content {
      max-width: 800px;
  }
}
@media screen and (max-width: 1024px) {
  .portfolioListing {
    padding-top: 30px;
    padding-bottom: 50px;
  }
  .tab-list .tabs {
    flex-wrap: wrap;
    justify-content: center;
    justify-items: center;
    align-items: center;
    margin: auto;
  }
  .tabs li {
    width: 200px;
    flex: none;
  }
}
@media screen and (max-width: 767px) {
  .tabs li {
    width: 160px;
    font-size: 15px;
    padding: 10px 12px;
  }
  .portfolioListing {
    padding-top: 20px;
  }
 .portfolioListing .ourRecentWorkHomeBox {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media screen and (max-width: 480px) {
  .portfolioListing .recentWorkListing {
    margin: 0;
  }
  .tab-list .tabs {
    gap: 10px;
  }
}



  /* //" {tabs[activeTab].content}" */
